import { fireAuth, fireFunc } from '../firebase';
import { EThree } from '@virgilsecurity/e3kit-browser';
const getToken = fireFunc.httpsCallable('getVirgilJwt');
const initializeFunction = () => getToken().then((result) => result.data.token);
export default {
  methods: {
    getCurrentUser() {
      return new Promise((resolve, reject) => {
        const unsubscribe = fireAuth.onAuthStateChanged((user) => {
          unsubscribe();
          resolve(user);
        }, reject);
      });
    },
    signInUser(email, password) {
      fireAuth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          EThree.initialize(initializeFunction).then((eThree) => {
            eThree.hasLocalPrivateKey().then((hasLocalPrivateKey) => {
              if (!hasLocalPrivateKey) {
                eThree.restorePrivateKey(password).then(() => {
                  //this.updateLogged(true);
                  this.$router.push('Dashboard');
                });
              }
            });
          });
        })
        .catch(
          // eslint-disable-next-line
          (error) => {
            console.log('Something happened.', error);
          },
        );
    },
    signUpUser(email, password) {
      fireAuth
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          EThree.initialize(initializeFunction)
            .then((eThree) => {
              eThree
                .register()
                .then(() => {
                  eThree
                    .backupPrivateKey(password)
                    .then(() => {
                      this.updateLogged(true);
                    })
                    .catch((e) => console.error('error: ', e));
                })
                .catch((e) => console.error('error: ', e));
            })
            .catch((error) => {
              // Error handling
              // const code = error.code;
              // code === 'unauthenticated' if user not authenticated
              console.log('error ', error);
            });
        })
        .catch(
          // eslint-disable-next-line
          (error) => {
            console.log('Something happened.', error);
          },
        );
    },
    signOut() {
      EThree.initialize(initializeFunction).then((eThree) => {
        eThree
          .cleanup()
          .then(() => {
            fireAuth
              .signOut()
              .then(() => {
                this.$router.push('/login');
                this.logged = false;
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.log('error', error);
              });
          })
          .catch((e) => console.error('error: ', e));
      });
    },
  },
};
