import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/functions';

let config = {
  apiKey: 'AIzaSyAdYXE2p5IN6ur5ymjO3ZgDnYKI0wiEU7w',
  authDomain: 'journfulness.firebaseapp.com',
  databaseURL: 'https://journfulness.firebaseio.com',
  projectId: 'journfulness',
  storageBucket: 'journfulness.appspot.com',
  messagingSenderId: '850188421923',
  appId: '1:850188421923:web:69551b2f6f04589f8f9a96',
};

firebase.initializeApp(config);
export const fireApp = firebase;
export const fireAuth = firebase.auth();
export const fireDb = firebase.database();
export const fireFunc = firebase.functions();
