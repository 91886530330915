export const loginI18N = {
  en: {
    loginButton: {
      actionText: "Login",
      actionButton: "Send",
      linkButton: "Register",
    },
    registerButton: {
      actionText: "Register",
      actionButton: "Register",
      linkButton: "Login",
    },
    clearButton: "Clear",
  },
  es: {
    loginButton: {
      actionText: "Ingresar",
      actionButton: "Enviar",
      linkButton: "Registrar",
    },
    registerButton: {
      actionText: "Registrar",
      actionButton: "Registrar",
      linkButton: "Ingresar",
    },
    clearButton: "Limpiar",
  },
};
