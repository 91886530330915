import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import { fireAuth } from '../firebase';

const routes = [
  {
    path: '',
    component: Login,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reminders',
    name: 'Reminders',
    component: () => import('../views/Reminders.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/log',
    name: 'Log',
    component: () => import('../views/Log.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((rec) => rec.meta.requiresAuth)) {
    const user = fireAuth.currentUser;
    // check auth state of user
    if (user) {
      next(); // user signed in, proceed to route
    } else {
      next('/login'); // user not signed in, route to login
    }
  } else {
    next(); // route does not require auth
  }
});

export default router;
