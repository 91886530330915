export const topbarI18N = {
  en: {
    title: "Journfulness",
    log: "Logs",
    logOut: "Logout",
  },
  es: {
    title: "Journfulness",
    log: "Registro",
    logOut: "Salir",
  },
};
