import './firebase';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@material/mwc-icon-button';
import '@material/mwc-top-app-bar-fixed';
import '@material/mwc-drawer';
import '@material/mwc-textfield';
import '@material/mwc-button';
import '@material/mwc-list/mwc-list';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-fab';
import '@material/mwc-dialog';
import '@material/mwc-textarea';
import '@material/mwc-switch';
import '@material/mwc-select';
import '@material/mwc-formfield';
import '@material/mwc-circular-progress';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { IonicVue } from '@ionic/vue';
import { addIcons } from 'ionicons';
import * as allIcons from 'ionicons/icons';

import { LocalNotifications } from '@capacitor/local-notifications';
import { Capacitor } from '@capacitor/core';

// Add all the icons to Ionicons
addIcons(allIcons);

const app = createApp(App);
app.config.unwrapInjectedRef = true;
app.use(IonicVue);
app.use(router);
app.component('QuillEditor', QuillEditor);
router.isReady().then(() => {
  app.mount('#app');
});

if (Capacitor.isNativePlatform()) {
  // Request notification permission
  try {
    const status = await LocalNotifications.requestPermissions();

    console.log('Notification permission status:', status);
  } catch (error) {
    console.error('Error requesting notification permission:', error);
  }

  // Add listener for local notification click event
  LocalNotifications.addListener(
    'localNotificationActionPerformed',
    (action) => {
      console.log('Local notification clicked:', action);
    },
  );

  // Add listener for local notification trigger event
  LocalNotifications.addListener(
    'localNotificationReceived',
    (notification) => {
      console.log('Local notification received:', notification);
    },
  );
}
