<template>
  <div class="formContainer">
    <div class="formLogin">
      <div class="formFields">
        <h1>{{ actionText }}</h1>
        <mwc-textfield
          id="text-email"
          outlined
          minlength="3"
          label="Email"
          required
        >
        </mwc-textfield>
      </div>
      <div class="formFields">
        <mwc-textfield
          id="text-password"
          type="password"
          outlined
          minlength="3"
          label="Password"
          required
        >
        </mwc-textfield>
      </div>
      <div class="actionButtons">
        <mwc-button
          id="link-action-button"
          class="actionButton"
          slot="primaryAction"
          @click="goToLink"
        >
          {{ linkButton }}
        </mwc-button>
      </div>
      <div class="actionButtons">
        <mwc-button
          raised
          id="primary-action-button"
          class="actionButton"
          slot="primaryAction"
          @click="submitAction"
        >
          {{ actionButton }}
        </mwc-button>
        <mwc-button
          raised
          slot="secondary-action-button"
          class="actionButton"
          @click="handleClear"
        >
          {{ clearButton }}
        </mwc-button>
      </div>
    </div>
  </div>
</template>
<script>
import { loginI18N } from '../i18n/login';
import firebaseMixin from '../mixins/firebaseMixin';
import '@material/mwc-textfield';
import '@material/mwc-button';

export default {
  name: 'Login',
  mixins: [firebaseMixin],
  data() {
    return {
      loginI18N,
      actionText: loginI18N.en.loginButton.actionText,
      actionButton: loginI18N.en.loginButton.actionText,
      linkButton: loginI18N.en.loginButton.linkButton,
      clearButton: loginI18N.en.clearButton,
      user: null,
    };
  },
  mounted() {
    this.getCurrentUser()
      .then((user) => {
        this.user = user;
        this.$router.push('Dashboard');
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    goToLink() {
      switch (this.linkButton) {
        case 'Login':
          this.actionText = loginI18N.en.loginButton.actionText;
          this.actionButton = loginI18N.en.loginButton.actionButton;
          this.linkButton = loginI18N.en.loginButton.linkButton;
          break;
        case 'Register':
          this.actionText = loginI18N.en.registerButton.actionText;
          this.actionButton = loginI18N.en.registerButton.actionButton;
          this.linkButton = loginI18N.en.registerButton.linkButton;
          break;
      }
    },
    handleClear() {
      let txtEmail = this.$el.querySelector('#text-email');
      let txtPassword = this.$el.querySelector('#text-password');

      txtEmail.value = '';
      txtPassword.value = '';
    },
    submitAction() {
      let txtEmail = this.$el.querySelector('#text-email');
      let txtPassword = this.$el.querySelector('#text-password');
      const isValid = txtEmail.checkValidity() && txtPassword.checkValidity();

      if (isValid) {
        switch (this.actionText) {
          case 'Login':
            this.signInUser(txtEmail.value, txtPassword.value);

            txtEmail.value = '';
            txtPassword.value = '';
            break;
          case 'Register':
            this.signUpUser(txtEmail.value, txtPassword.value);

            txtEmail.value = '';
            txtPassword.value = '';
            break;
        }
      }
    },
  },
};
</script>
<style scoped>
.formContainer {
  display: flex;
}

.formLogin {
  margin: auto;
}

.formFields {
  margin: 15px;
}

.actionButtons {
  text-align: center;
}

.actionButton {
  margin: 10px;
}
</style>
