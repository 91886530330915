<template>
  <div id="app">
    <mwc-drawer id="drawer" hasHeader type="modal">
      <span slot="title">Menu</span>
      <div class="drawer-content">
      <mwc-list>
        <mwc-list-item v-if="isDevice" @click="goToReminder">Reminders</mwc-list-item>
        <mwc-list-item @click="goToLog">{{ topbarI18N.en.log }}</mwc-list-item>
        <mwc-list-item  @click="handleLogout">{{ topbarI18N.en.logOut }}</mwc-list-item>
      </mwc-list>
      </div>
      <div slot="appContent">
        <mwc-top-app-bar-fixed dense>
          <mwc-icon-button slot="navigationIcon" v-if="logged" icon="menu"></mwc-icon-button>
          <div slot="title">
            <router-link to="/dashboard">{{ topbarI18N.en.title }}</router-link>
          </div>
          <div><router-view/></div>
        </mwc-top-app-bar-fixed>
      </div>
    </mwc-drawer>
  </div>
</template>

<script>
import { topbarI18N } from './i18n/topbar';
import firebaseMixin from './mixins/firebaseMixin';
import { fireAuth } from './firebase';
import { Capacitor } from '@capacitor/core';
export default {
  name: 'App',
  data() {
    return {
      logged: false,
      topbarI18N,
      isDevice: Capacitor.isNativePlatform(),
    };
  },
  mixins: [firebaseMixin],
  methods: {
    handleAbout() {
      this.$router.push('About');
    },
    handleLogout() {
      this.signOut();
      this.toggleDrawer();
    },
    goToLog() {
      this.$router.push('Log');
    },
    goToReminder() {
      this.$router.push('Reminders');
    },
    toggleDrawer() {
      const drawer = this.$el.querySelector('#drawer');
      drawer.open = !drawer.open;
    },
  },
  created() {
    fireAuth.onAuthStateChanged((user) => {
      if (user) {
        this.logged = true;
      } else {
        this.logged = false;
      }
    });
  },
  mounted() {
    const drawer = this.$el.querySelector('#drawer');
    if (drawer) {
      const container = drawer.parentNode;
      container.addEventListener('MDCTopAppBar:nav', () => {
        drawer.open = !drawer.open;
      });
    }
  },
};
</script>

<style>
#app {
  margin: 0;
  padding: 0;
}
a,
a:visited {
  color: white;
  text-decoration: none;
  padding: 5px;
}

.option-menu {
  padding: 0 10px;
}
</style>
